import { type } from "@testing-library/user-event/dist/type";

export const Alice = {
  name: "Alice",
  brief: "Alice works at office on Mondays, Tuesdays and Thursdays. She leaves home at 7:00 and returns at 19:00.",
  values: [
    { 
      shortTexts: "Run the washer and dryer once",
      fullTexts: "The washer and dryer should complete one cycle each next week.",
      params: [
        {
          "appliance": "Washer",
          "n": 1,
        },
        {
          "appliance": "Dryer",
          "n": 1,
        },
      ],
    },
    {
      shortTexts: "No washer or dryer running between 23:00 to 7:00",
      fullTexts: "The washer and dryer should not operate from 23:00 to 7:00.",
      params: [
        {
          "appliance": "Washer",
          "forbiddenTimes": [[0, 7], [23, 31], [47, 55], [71, 79], [95, 103], [119, 127], [143, 151], [167, 168]],
        },
        {
          "appliance": "Dryer",
          "forbiddenTimes": [[0, 7], [23, 31], [47, 55], [71, 79], [95, 103], [119, 127], [143, 151], [167, 168]],
        },
      ],
    },
    {
      shortTexts: "Use dishwasher twice (once by Thursday and once after Thursday)",
      fullTexts: "The dishwasher should run at least two cycles next week. Alice prefers once between Monday and Thursday, and once between Friday and Sunday.",
      params: [
        {
            "appliance": "Dishwasher",
            "n": 2,
        },
        {
            "appliance": "Dishwasher",
            "prerequisiteTimes": [[0, 95]],
        },
        {
            "appliance": "Dishwasher",
            "prerequisiteTimes": [[96, 168]],
        },
      ],
    },
    {
      shortTexts: "Charge the electric vehicle before office days (Monday, Tuesday and Thursday)",
      fullTexts: "The electric vehicle should charge before office workdays (Monday, Tuesday and Thursday), finishing before departure from home.",
      params: [
        {
          "appliance": "Vehicle",
          "n": 3,
        },
        {
          "appliance": "Vehicle",
          "prerequisiteTimes": [[19, 31]],
        },
        {
          "appliance": "Vehicle",
          "prerequisiteTimes": [[48, 31+48]],
        },
        {
          "appliance": "Vehicle",
          "prerequisiteTimes": [[0, 7], [144, 168]],
        }
      ],
    }
  ],

};

export const BobNightShift = {
  name: "Bob",
  brief: "Bob works night shifts. He leaves home at 22:00 and arrives home at 10:00 (next day).",
  values: [
    { 
      shortTexts: "Run the washer and dryer twice each",
      fullTexts: "The washer and dryer should complete two cycles each next week.",
      params: [
        {
          "appliance": "Washer",
          "n": 2,
        },
        {
          "appliance": "Dryer",
          "n": 2,
        },
      ],
    },
    {
      shortTexts: "Only operate the washer and dryer between 07:00 and 15:00",
      fullTexts: "The washer and dryer can only operate from 07:00 to 15:00. They should not run from 23:00 to 07:00 (late hours) or 15:00 to 23:00 (when Bob is home.) ",
      params: [
        {
          "appliance": "Washer",
          "forbiddenTimes": [[0, 6], [15, 23], [24, 30], [39, 47], [48, 54], [63, 71], [72, 78], [87, 95], [96, 102], [111, 119], [120, 126], [135, 143], [144, 150], [159, 167]],
        },
        {
          "appliance": "Dryer",
          "forbiddenTimes": [[0, 6], [15, 23], [24, 30], [39, 47], [48, 54], [63, 71], [72, 78], [87, 95], [96, 102], [111, 119], [120, 126], [135, 143], [144, 150], [159, 167]],
        },
      ],
    },
    {
      shortTexts: "Run the dishwasher twice next week",
      fullTexts: "The dishwasher should run at least two cycles next week.",
      params: [
        {
          "appliance": "Dishwasher",
          "n": 2,
        },
      ],
    },
    {
      shortTexts: "Charge the electric vehicle twice",
      fullTexts: "The electric vehicle should charge at least two cycles next week.",
      params: [
        {
          "appliance": "Vehicle",
          "n": 2,
        },
      ],
    },
    {
      shortTexts: "Run appliances once on weekdays and once on weekends",
      fullTexts: "Bob prefers all appliances to run (or charge) once on weekdays and once on weekends.",
      params: [
        {
          "appliance": "Washer",
          "prerequisiteTimes": [[0, 119]],
        },
        {
          "appliance": "Dryer",
          "prerequisiteTimes": [[0, 119]],
        },
        {
          "appliance": "Dishwasher",
          "prerequisiteTimes": [[0, 119]],
        },
        {
          "appliance": "Vehicle",
          "prerequisiteTimes": [[0, 119]],
        },
        {
          "appliance": "Washer",
          "prerequisiteTimes": [[120, 168]],
        },
        {
          "appliance": "Dryer",
          "prerequisiteTimes": [[120, 168]],
        },
        {
          "appliance": "Dishwasher",
          "prerequisiteTimes": [[120, 168]],
        },
        {
          "appliance": "Vehicle",
          "prerequisiteTimes": [[120, 168]],
        },
      ],
    }
  ],
};

export const defaultModel = {
  Alice: {
    name: "Alice",
    appliances: [
    {
        "label": "Washer",
        "duration": 2,
        "rate": 0.75,
        "min_required_cycles": [
            {
                "timesteps": [
                    7,
                    8,
                    9,
                    10,
                    11,
                    12,
                    13,
                    14,
                    15,
                    16,
                    17,
                    18,
                    19,
                    20,
                    21,
                    22,
                    31,
                    32,
                    33,
                    34,
                    35,
                    36,
                    37,
                    38,
                    39,
                    40,
                    41,
                    42,
                    43,
                    44,
                    45,
                    46,
                    55,
                    56,
                    57,
                    58,
                    59,
                    60,
                    61,
                    62,
                    63,
                    64,
                    65,
                    66,
                    67,
                    68,
                    69,
                    70,
                    79,
                    80,
                    81,
                    82,
                    83,
                    84,
                    85,
                    86,
                    87,
                    88,
                    89,
                    90,
                    91,
                    92,
                    93,
                    94,
                    103,
                    104,
                    105,
                    106,
                    107,
                    108,
                    109,
                    110,
                    111,
                    112,
                    113,
                    114,
                    115,
                    116,
                    117,
                    118,
                    127,
                    128,
                    129,
                    130,
                    131,
                    132,
                    133,
                    134,
                    135,
                    136,
                    137,
                    138,
                    139,
                    140,
                    141,
                    142,
                    151,
                    152,
                    153,
                    154,
                    155,
                    156,
                    157,
                    158,
                    159,
                    160,
                    161,
                    162,
                    163,
                    164,
                    165,
                    166
                ],
                "min_required_cycles": 1
            }
        ],
        "dependencies": [
            null,
            null,
            null,
            null
        ]
    },
    {
        "label": "Dryer",
        "duration": 3,
        "rate": 1.5,
        "min_required_cycles": [
            {
                "timesteps": [
                    7,
                    8,
                    9,
                    10,
                    11,
                    12,
                    13,
                    14,
                    15,
                    16,
                    17,
                    18,
                    19,
                    20,
                    21,
                    22,
                    31,
                    32,
                    33,
                    34,
                    35,
                    36,
                    37,
                    38,
                    39,
                    40,
                    41,
                    42,
                    43,
                    44,
                    45,
                    46,
                    55,
                    56,
                    57,
                    58,
                    59,
                    60,
                    61,
                    62,
                    63,
                    64,
                    65,
                    66,
                    67,
                    68,
                    69,
                    70,
                    79,
                    80,
                    81,
                    82,
                    83,
                    84,
                    85,
                    86,
                    87,
                    88,
                    89,
                    90,
                    91,
                    92,
                    93,
                    94,
                    103,
                    104,
                    105,
                    106,
                    107,
                    108,
                    109,
                    110,
                    111,
                    112,
                    113,
                    114,
                    115,
                    116,
                    117,
                    118,
                    127,
                    128,
                    129,
                    130,
                    131,
                    132,
                    133,
                    134,
                    135,
                    136,
                    137,
                    138,
                    139,
                    140,
                    141,
                    142,
                    151,
                    152,
                    153,
                    154,
                    155,
                    156,
                    157,
                    158,
                    159,
                    160,
                    161,
                    162,
                    163,
                    164,
                    165,
                    166
                ],
                "min_required_cycles": 1
            }
        ],
        "dependencies": [
            null,
            null,
            null,
            null
        ]
    },
    {
      "label": "Dishwasher",
      "duration": 1,
      "rate": 1.2,
      "min_required_cycles": [
          {
              "timesteps": [
                  0,
                  1,
                  2,
                  3,
                  4,
                  5,
                  6,
                  7,
                  8,
                  9,
                  10,
                  11,
                  12,
                  13,
                  14,
                  15,
                  16,
                  17,
                  18,
                  19,
                  20,
                  21,
                  22,
                  23,
                  24,
                  25,
                  26,
                  27,
                  28,
                  29,
                  30,
                  31,
                  32,
                  33,
                  34,
                  35,
                  36,
                  37,
                  38,
                  39,
                  40,
                  41,
                  42,
                  43,
                  44,
                  45,
                  46,
                  47,
                  48,
                  49,
                  50,
                  51,
                  52,
                  53,
                  54,
                  55,
                  56,
                  57,
                  58,
                  59,
                  60,
                  61,
                  62,
                  63,
                  64,
                  65,
                  66,
                  67,
                  68,
                  69,
                  70,
                  71,
                  72,
                  73,
                  74,
                  75,
                  76,
                  77,
                  78,
                  79,
                  80,
                  81,
                  82,
                  83,
                  84,
                  85,
                  86,
                  87,
                  88,
                  89,
                  90,
                  91,
                  92,
                  93,
                  94
              ],
              "min_required_cycles": 1
          },
          {
              "timesteps": [
                  96,
                  97,
                  98,
                  99,
                  100,
                  101,
                  102,
                  103,
                  104,
                  105,
                  106,
                  107,
                  108,
                  109,
                  110,
                  111,
                  112,
                  113,
                  114,
                  115,
                  116,
                  117,
                  118,
                  119,
                  120,
                  121,
                  122,
                  123,
                  124,
                  125,
                  126,
                  127,
                  128,
                  129,
                  130,
                  131,
                  132,
                  133,
                  134,
                  135,
                  136,
                  137,
                  138,
                  139,
                  140,
                  141,
                  142,
                  143,
                  144,
                  145,
                  146,
                  147,
                  148,
                  149,
                  150,
                  151,
                  152,
                  153,
                  154,
                  155,
                  156,
                  157,
                  158,
                  159,
                  160,
                  161,
                  162,
                  163,
                  164,
                  165,
                  166,
                  167
              ],
              "min_required_cycles": 1
          }
      ],
      "dependencies": [
          null,
          null,
          null,
          null
      ]
    },
    {
      "label": "Vehicle",
      "duration": 4,
      "rate": 5,
      "min_required_cycles": [
          {
              "timesteps": [
                  0,
                  1,
                  2,
                  3,
                  4,
                  5,
                  6,
                  144,
                  145,
                  146,
                  147,
                  148,
                  149,
                  150,
                  151,
                  152,
                  153,
                  154,
                  155,
                  156,
                  157,
                  158,
                  159,
                  160,
                  161,
                  162,
                  163,
                  164,
                  165,
                  166,
                  167
              ],
              "min_required_cycles": 1
          },
          {
              "timesteps": [
                  19,
                  20,
                  21,
                  22,
                  24,
                  25,
                  26,
                  27,
                  28,
                  29,
                  30
              ],
              "min_required_cycles": 1
          },
          {
              "timesteps": [
                  48,
                  49,
                  50,
                  51,
                  52,
                  53,
                  54,
                  55,
                  56,
                  57,
                  58,
                  59,
                  60,
                  61,
                  62,
                  63,
                  64,
                  65,
                  66,
                  67,
                  68,
                  69,
                  70,
                  72,
                  73,
                  74,
                  75,
                  76,
                  77,
                  78
              ],
              "min_required_cycles": 1
          }
      ],
      "dependencies": [
          null,
          null,
          null,
          null
      ]
    }
  ],
  },

  Bob: {
    name: "Bob",
    appliances: [
    {
        "label": "Washer",
        "duration": 2,
        "rate": 0.75,
        "min_required_cycles": [
            {
                "timesteps": [
                    7,
                    8,
                    9,
                    10,
                    11,
                    12,
                    13,
                    14,
                    31,
                    32,
                    33,
                    34,
                    35,
                    36,
                    37,
                    38,
                    55,
                    56,
                    57,
                    58,
                    59,
                    60,
                    61,
                    62,
                    79,
                    80,
                    81,
                    82,
                    83,
                    84,
                    85,
                    86,
                    103,
                    104,
                    105,
                    106,
                    107,
                    108,
                    109,
                    110
                ],
                "min_required_cycles": 1
            },
            {
                "timesteps": [
                    127,
                    128,
                    129,
                    130,
                    131,
                    132,
                    133,
                    134,
                    151,
                    152,
                    153,
                    154,
                    155,
                    156,
                    157,
                    158
                ],
                "min_required_cycles": 1
            }
        ],
        "dependencies": [
            null,
            null,
            null,
            null
        ]
    },
    {
        "label": "Dryer",
        "duration": 3,
        "rate": 1.5,
        "min_required_cycles": [
          {
            "timesteps": [
                7,
                8,
                9,
                10,
                11,
                12,
                13,
                14,
                31,
                32,
                33,
                34,
                35,
                36,
                37,
                38,
                55,
                56,
                57,
                58,
                59,
                60,
                61,
                62,
                79,
                80,
                81,
                82,
                83,
                84,
                85,
                86,
                103,
                104,
                105,
                106,
                107,
                108,
                109,
                110
            ],
            "min_required_cycles": 1
        },
        {
            "timesteps": [
                127,
                128,
                129,
                130,
                131,
                132,
                133,
                134,
                151,
                152,
                153,
                154,
                155,
                156,
                157,
                158
            ],
            "min_required_cycles": 1
        }
        ],
        "dependencies": [
            null,
            null,
            null,
            null
        ]
    },
    {
        "label": "Dishwasher",
        "duration": 1,
        "rate": 1.2,
        "min_required_cycles": [
          {
            "timesteps": [
                7,
                8,
                9,
                10,
                11,
                12,
                13,
                14,
                31,
                32,
                33,
                34,
                35,
                36,
                37,
                38,
                55,
                56,
                57,
                58,
                59,
                60,
                61,
                62,
                79,
                80,
                81,
                82,
                83,
                84,
                85,
                86,
                103,
                104,
                105,
                106,
                107,
                108,
                109,
                110
            ],
            "min_required_cycles": 1
        },
        {
            "timesteps": [
                127,
                128,
                129,
                130,
                131,
                132,
                133,
                134,
                151,
                152,
                153,
                154,
                155,
                156,
                157,
                158
            ],
            "min_required_cycles": 1
        }
        ],
        "dependencies": [
            null,
            null,
            null,
            null
        ]
    },
    {
      "label": "Vehicle",
      "duration": 4,
      "rate": 5,
      "min_required_cycles": [
          {
              "timesteps": [
                  10,
                  11,
                  12,
                  13,
                  14,
                  15,
                  16,
                  17,
                  18,
                  19,
                  20,
                  21,
                  34,
                  35,
                  36,
                  37,
                  38,
                  39,
                  40,
                  41,
                  42,
                  43,
                  44,
                  45,
                  58,
                  59,
                  60,
                  61,
                  62,
                  63,
                  64,
                  65,
                  66,
                  67,
                  68,
                  69,
                  82,
                  83,
                  84,
                  85,
                  86,
                  87,
                  88,
                  89,
                  90,
                  91,
                  92,
                  93,
                  106,
                  107,
                  108,
                  109,
                  110,
                  111,
                  112,
                  113,
                  114,
                  115,
                  116,
                  117
              ],
              "min_required_cycles": 1
          },
          {
              "timesteps": [
                  120,
                  121,
                  122,
                  123,
                  124,
                  125,
                  126,
                  127,
                  128,
                  129,
                  130,
                  131,
                  132,
                  133,
                  134,
                  135,
                  136,
                  137,
                  138,
                  139,
                  140,
                  141,
                  142,
                  143,
                  144,
                  145,
                  146,
                  147,
                  148,
                  149,
                  150,
                  151,
                  152,
                  153,
                  154,
                  155,
                  156,
                  157,
                  158,
                  159,
                  160,
                  161,
                  162,
                  163,
                  164,
                  165,
                  166,
                  167
              ],
              "min_required_cycles": 1
          }
      ],
      "dependencies": [
          null,
          null,
          null,
          null
      ]
    }
    ],
  }
};